<script>
export default {
	props: ['OfferItem'],
	hookZone: ['discount'],
}
</script>

<template>
	<span class="discount d-inline-block discount font-1" v-if="OfferItem.discountPct > 0">
		{{ OfferItem.discountPct }}% OFF
	</span>
</template>

<style lang="scss" scoped>
.discount {
	padding: 2px 5px;
	border-radius: 4px;
}
</style>
