<script>
import countdownMixin from '../mixins/countdown-mixin'

export default {
	mixins: [countdownMixin('cybermonday/secondsLeft')],
	hookZone: ['bottom'],
	showHook(_, parent) {
		return !!parent.$store.get('cybermonday/secondsLeft')
	},
	data() {
		return {
			close: false,
		}
	},
	methods: {
		onClose() {
			this.close = !this.close
			this.$emit('close')
		},
		goCybermonday() {
			const routeURL = this.$router.resolve({ name: 'pre-cybermonday' }).href
			window.open(routeURL, '_blank')
		},
	},
}
</script>

<template>
	<div :class="['t-container py-2 t-container--desktop']">
		<div class="d-flex">
			<div @click="goCybermonday" class="pointer">
				<div class="poppins white--text">
					<div class="font-2 pl-4 text-center">CyberMonday comienza en:</div>
					<!-- <div v-if="$b.m" style="position: absolute; top: 0; right: 0">
						<v-icon color="white" @click="onClose"> mdi-close </v-icon>
					</div> -->
				</div>
				<div class="timer d-flex justify-center" :class="$bem('timer')">
					<div
						class="d-flex white--text align-center poppins font-weight-bold"
						:class="[$bem('__container'), $b.d && $bem('__container--desktop')]"
					>
						<div :class="$bem('__time-unit-container')" class="px-2">
							<div>
								<div :class="$bem('__digits')">{{ days }}</div>
								<div :class="$bem('__unit')">DÍAS</div>
							</div>
						</div>
						<div :class="$bem('__separator')">:</div>
						<div :class="$bem('__time-unit-container')" class="px-2">
							<div>
								<div :class="$bem('__digits')">{{ hours }}</div>
								<div :class="$bem('__unit')">HORAS</div>
							</div>
						</div>
						<div :class="$bem('__separator')">:</div>
						<div :class="$bem('__time-unit-container')" class="px-2">
							<div>
								<div :class="$bem('__digits')">{{ minutes }}</div>
								<div :class="$bem('__unit')">MINUTOS</div>
							</div>
						</div>
						<div :class="$bem('__separator')">:</div>
						<div :class="$bem('__time-unit-container')" class="px-2">
							<div>
								<div :class="$bem('__digits')">{{ seconds }}</div>
								<div :class="$bem('__unit')">SEGUNDOS</div>
							</div>
						</div>
					</div>
				</div>
				<div class="text-center white--text pl-4">Enterate de las promos clickeando acá</div>
			</div>
			<div @click="onClose" class="pointer white--text d-flex ml-3">
				<div>
					<v-icon color="white">{{
						close ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left'
					}}</v-icon>
					<div class="font-0" style="writing-mode: vertical-rl; transform: rotate(180deg)">
						Cyber Monday
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?:wght@800&family=Poppins:wght@500;700&display=swap');

.pointer {
	cursor: pointer;
}

.t-container {
	overflow: hidden;
	position: relative;
	background: linear-gradient(to bottom, #3a90f7, #7d44f9);
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

	&--desktop {
		border-radius: 0 10px 10px 0;
	}
}

.poppins {
	font-family: 'Poppins', sans-serif;
}

.timer {
	padding-left: 15px;

	&__container {
		padding: 5px 0 5px 5px;
		position: relative;
	}

	&__separator {
		padding: 0 5px 0 5px;
	}
	&__time-unit-container {
		position: relative;
		border: 1px solid white;
		text-align: center;
		// padding: 2px 1px 2px 1px;
		padding-top: 8px;
		border-radius: 8px;
	}
	&__digits {
		font-size: 1.4rem;
		line-height: 0.8rem;
	}
	&__unit {
		font-size: 0.7rem;
	}

	&__bg-rectangle {
		position: absolute;
		background: #a55eff;
		top: -5px;
		right: -1px;
		z-index: -1;
		padding: 5px;
		border-radius: 5px;
	}
}
</style>
