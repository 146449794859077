const exceptions = ['a', 'con', 'de', 'en', 'para', 'por', 'y', 'o', 'ni', 'la']

export default {
	filters: {
		lowerCase(value) {
			return value
				.split(' ')
				.map((w) => (exceptions.includes(w.toLowerCase()) ? w.toLowerCase() : w))
				.join(' ')
		},
	},
}
