<script>
import carousel from '#/v-shop/vue-src/v-shop/dynamic-pages-components/comp_dpc-CarouselMultiple.vue'

export default {
	extends: carousel,
	data() {
		return {
			activeProducts: 1,
		}
	},
	computed: {
		carouselConfig() {
			return this.$b.m
				? { slidesPerPage: 2, gutter: 5 }
				: this.$b.d && (this.data.position != 'top' || this.hasBoardProduct)
				? { slidesPerPage: 2, gutter: 5 }
				: carousel.computed.carouselConfig.apply(this)
		},
		boardProductCol() {
			if (this.$b.d) {
				if (this.data.inverted) return 'left'
				else return 'right'
			} else return ''
		},
		products() {
			this.data.tabs.map((t) => {})
			return this.data.tabs.map((t) => {
				let res = []
				let [...arr] = t.products
				while (arr.length) {
					res.push(arr.splice(0, 6))
				}

				t.products = res
			})
		},
	},

	methods: {},

	// mounted() {
	// 	let prod = this.data.tabs.map((t) => {
	// 		let res = []
	// 		let [...arr] = t.products
	// 		while (arr.length) {
	// 			res.push(arr.splice(0, 6))
	// 		}

	// 		t.products = res
	// 		console.log(t.products)
	// 	})
	// },
}
</script>

<template>
	<v-container v-if="data" :class="$bem('carousel-multiple')">
		<Title
			v-if="data.textEnabled"
			:title="data.title"
			:btn="{ link: data.link, linkText: data.linkText }"
			:titleStyle="data.titleStyle"
		/>

		<v-row
			:class="{
				'flex-column-reverse': data.inverted && hasBoardProduct && $b.mt,
				'flex-row-reverse': data.inverted && hasBoardProduct && $b.d,
			}"
		>
			<v-col :cols="$b.d ? 4 : 12" v-if="hasBoardProduct" :class="boardProductCol" class="secondary">
				<ProductCard
					:product="data.boardProduct"
					:list-id="`related-products-board-product-${data.boardProduct.collectionId}`"
					:verticalCard="$b.mt ? false : true"
					featured
					:timerProduct="limitedTimeOffer ? true : false"
					:position="data.inverted ? 'left' : 'right'"
					class="ma-10"
				/>
			</v-col>
			<v-col :cols="hasBoardProduct && $b.d ? 8 : 12">
				<v-row :class="{ 'flex-row-reverse': right }">
					<v-col cols="12" :sm="top ? 12 : 2">
						<v-tabs
							show-arrows
							v-model="tab"
							:centered="top"
							:center-active="top"
							:vertical="$b.d && !top"
							:color="$vars.cta"
							class="h100"
							:class="{
								'slider-right': left && $b.d,
								'top-underline': top && $b.d,
								'slider-top mb-3': top,
								'px-2': !top && $b.m,
							}"
							:slider-color="$vars.cta"
						>
							<v-tab
								v-for="(item, n) in data.tabs"
								:key="n"
								:class="{ 'justify-start': left, 'justify-end': right }"
								active-class="cta--text"
							>
								<v-icon v-if="data.titleType == 'icon'">{{ item.titleIcon }}</v-icon>
								<Media v-else-if="data.titleType == 'img'" :src="item.titleImg" />
								<span v-else>
									{{ item.title }}
								</span>
							</v-tab>
						</v-tabs>
					</v-col>
					<v-col cols="12" :sm="top ? 12 : 10" :class="{ 'border-left': left, 'border-right': right }">
						<v-tabs-items v-model="tab" touchless>
							<v-tab-item v-for="(item, n) in data.tabs" :key="n" class="pa-1">
								<div>
									<div>
										<select v-model="activeProducts">
											<option>
												<v-btn> <v-icon>mdi-chevron-left</v-icon></v-btn>
											</option>
											<option>
												<v-btn> <v-icon>mdi-chevron-right</v-icon></v-btn>
											</option>
										</select>
									</div>
									<div class="d-flex flex-wrap" v-show="activeProducts">
										<div class="d-flex flex-column col-4 px-2" v-for="(product, i) in el" :key="i">
											<ProductCard :product="product" :list-index="i" list-id="related-products" />
										</div>
									</div>
								</div>

								<!-- <ProductsCarousel
									list-id="related-products"
									:products="item.products"
									v-bind="carouselConfig"
								/> -->
							</v-tab-item>
						</v-tabs-items>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<style lang="scss" scoped>
::v-deep .v-tabs-bar--is-mobile::after {
	content: ' ';
	border-bottom: 1px solid #ddd;
	width: 86%;
	position: absolute;
	margin-right: auto;
	margin-left: auto;
	left: 0;
	right: 0;
	bottom: 0;
}

.top-underline ::v-deep .v-slide-group__wrapper::after {
	content: ' ';
	border-bottom: 1px solid #ddd;
	width: 97.5%;
	position: absolute;
	margin-right: auto;
	margin-left: auto;
	left: 0;
	right: 0;
	bottom: 0;
}

::v-deep .v-tab:not(.v-tab--active) {
	color: var(--basetext) !important;
}

.border-left {
	border-left: 1px solid #ddd;
}

.border-right {
	border-right: 1px solid #ddd;
}
.slider-right ::v-deep .v-tabs-slider-wrapper {
	left: auto !important;
	right: 0;
}
::v-deep .v-tab {
	max-width: 100%;
}

::v-deep .v-tabs-items,
::v-deep .v-tabs > .v-tabs-bar {
	background: transparent !important;
}

::v-deep .v-tabs-slider::before {
	content: ' ';
	display: block;
	position: absolute;
	left: 50%;
	border-radius: 10px;
	transform: translateX(-50%);
	background-color: var(--cta);
	width: 12px;

	height: 11px;
	bottom: 38%;
}

.slider-top ::v-deep .v-tabs-slider::before {
	height: 8px;
	bottom: -1px;
}

.left {
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.right {
	border-top-right-radius: 30px;
	border-bottom-right-radius: 30px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

::v-deep div.product-card-design1-vertical-wrapper,
div.product-card-design2-vertical-wrapper,
div.product-card-design3-vertical-wrapper,
div.product-card-design4-vertical-wrapper,
div.product-card-design5-vertical-wrapper,
div.product-card-design6-vertical-wrapper,
div.product-card-design7-vertical-wrapper,
div.product-card-design8-vertical-wrapper {
	width: unset !important;
	min-width: unset !important;
}
</style>
