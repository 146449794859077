<script>
import BaseCartItem from '#/v-shop/vue-src/v-shop/cart-drawer/comp_CartItem.vue'
export default {
	extends: BaseCartItem,
	computed: {
		mediaContainerClass() {
			return 'mr-3'
		},
	},
}
</script>
