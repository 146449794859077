var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseAutocompleteSearchField',_vm._g(_vm._b({attrs:{"custom":_vm.custom},on:{"visible:change":function($event){$event && _vm.getTopSearches()}},scopedSlots:_vm._u([{key:"before-products",fn:function(ref){
var results = ref.results;
var open = ref.open;
var loading = ref.loading;
var searchQuery = ref.searchQuery;
return [(!loading && searchQuery && results.suggestions && results.suggestions.length)?_c('div',[_c('v-subheader',[_vm._v("Sugerencias de búsqueda")]),_c('v-list-item-group',_vm._l((_vm.sliceSuggestions(results.suggestions)),function(search,index){return _c('v-list-item',{key:("suggestion-" + index),staticClass:"common-link",on:{"click":function($event){return open(search)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(search)+" ")])],1)}),1)],1):_vm._e(),(_vm.$b.td && !loading && searchQuery && results.categories && results.categories.length)?_c('div',[_c('v-subheader',[_vm._v("Categorías relacionadas")]),_c('div',{staticClass:"d-flex align-center flex-wrap px-4"},_vm._l((results.categories),function(category,index){return _c('v-chip',{key:("category-" + index),staticClass:"autocomplete-chip mr-2 mb-2",on:{"click":function($event){return _vm.$shop.search(searchQuery, { categories: category.familyUrlNames })}}},[_vm._v(" "+_vm._s(category.name)+" ")])}),1)],1):_vm._e(),(_vm.$b.td && !loading && searchQuery && results.brands && results.brands.length)?_c('div',[_c('v-subheader',[_vm._v("Marcas relacionadas")]),_c('div',{staticClass:"d-flex align-center flex-wrap px-4"},_vm._l((results.brands),function(brand,index){return _c('v-chip',{key:("brand-" + index),staticClass:"autocomplete-chip mr-2 mb-2",on:{"click":function($event){return _vm.$shop.search(searchQuery, { brand: brand.urlName })}}},[_vm._v(" "+_vm._s(brand.name)+" ")])}),1)],1):_vm._e()]}},{key:"after-products",fn:function(ref){
var results = ref.results;
var loading = ref.loading;
var searchQuery = ref.searchQuery;
var searchHistory = ref.searchHistory;
var open = ref.open;
return [(_vm.$b.m && !loading && searchQuery && results.categories && results.categories.length)?_c('div',[_c('v-subheader',[_vm._v("Categorías relacionadas")]),_c('div',{staticClass:"d-flex align-center flex-wrap px-4"},_vm._l((results.categories),function(category,index){return _c('v-chip',{key:("category-" + index),staticClass:"autocomplete-chip mr-2 mb-2",on:{"click":function($event){return _vm.$shop.search(searchQuery, { categories: category.familyUrlNames })}}},[_vm._v(" "+_vm._s(category.name.split(' > ').slice(-2).join(' > '))+" ")])}),1)],1):_vm._e(),(_vm.$b.m && !loading && searchQuery && results.brands && results.brands.length)?_c('div',[_c('v-subheader',[_vm._v("Marcas relacionadas")]),_c('div',{staticClass:"d-flex align-center flex-wrap px-4"},_vm._l((results.brands),function(brand,index){return _c('v-chip',{key:("brand-" + index),staticClass:"autocomplete-chip mr-2 mb-2",on:{"click":function($event){return _vm.$shop.search(searchQuery, { brand: brand.urlName })}}},[_vm._v(" "+_vm._s(brand.name)+" ")])}),1)],1):_vm._e(),(_vm.topSearches)?_c('div',[_c('v-subheader',[_vm._v("Las búsquedas más populares")]),(_vm.topSearchesLoading)?_c('div',{staticClass:"pa-4"},[_c('v-progress-circular',{attrs:{"indeterminate":"","width":"2","size":"26"}})],1):_vm._e(),_c('v-list-item-group',_vm._l((_vm.topSearches),function(search,index){return _c('v-list-item',{key:index,staticClass:"common-link",attrs:{"dense":""},on:{"click":function($event){return open(search)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-trending-up")])],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(search)+" ")])],1)}),1)],1):_vm._e()]}}])},'BaseAutocompleteSearchField',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }