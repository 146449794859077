<script>
import BaseUserpanelLayout from '#/v-shop/vue-src/v-shop/account/comp_UserpanelLayout.vue'

export default {
	extends: BaseUserpanelLayout,
	computed: {
		colClass() {
			return 'px-sm-6 pt-sm-6'
		},
	},
}
</script>
