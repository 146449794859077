<script>
import BaseImagesSlider from '#/v-shop/vue-src/v-shop/dynamic-pages-components/comp_dpc-ImagesSlider.vue'
export default {
	extends: BaseImagesSlider,
	computed: {
		carouselProps() {
			return {
				showArrows: true,
			}
		},
		aspectRatio() {
			if (this.$b.d) {
				switch (this.data.sliderType) {
					case 'high':
						return 1264 / 680

					case 'mid':
						return 1264 / 500

					default:
						return 1264 / 297
				}
			} else {
				return 390 / 350
			}
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .carousel__arrow {
	background-color: #ffffff00 !important;
	border-radius: 50%;
}

::v-deep .ssr-carousel-mask > div {
	padding: 0;
}
</style>
