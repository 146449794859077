<script>
import BaseAppbarButtons from '#/v-shop-base-layout/vue-src/v-shop/appbar/comp_AppBar-Buttons.vue'

export default {
	extends: BaseAppbarButtons,
}
</script>

<template>
	<div class="appbar-buttons d-flex align-center justify-end">
		<v-btn
			v-if="user && $b.td"
			text
			:class="btnClass"
			:to="{ name: 'user.account' }"
			:color="accountIconColor || $vars.basetext"
		>
			<v-icon :size="iconSize" class="mr-1 btn">mdi-account-outline</v-icon>
			<span class="font-1" :style="`color: ${accountIconColor || $vars.basetext}`">{{
				user.firstname
			}}</span>
		</v-btn>
		<v-btn
			v-if="!user && $b.td"
			text
			:class="btnClass"
			@click="showLoginDrawer"
			:color="favIconColor || $vars.basetext"
			:loading="loginLoading"
		>
			<v-icon :size="iconSize" class="mr-1">mdi-account-outline</v-icon>
		</v-btn>
		<v-btn
			v-if="$b.td"
			text
			class="mr-2"
			:class="btnClass"
			@click="goToFavorites"
			:color="favIconColor || $vars.basetext"
		>
			<v-icon :size="iconSize" class="mr-1">mdi-heart-outline</v-icon>
		</v-btn>

		<v-btn
			v-if="purchaseMode != 'single_product'"
			text
			@click="showCartDrawer"
			class="mr-md-0 px-0"
			:color="cartIconColor || $vars.bastext"
		>
			<v-badge :content="orderItemsQty" :value="!!orderItemsQty" overlap aria-label="carrito">
				<v-icon :size="iconSize" class="mr-1">{{ $g('cartIcon', 'mdi-cart-outline') }}</v-icon>
			</v-badge>
		</v-btn>
	</div>
</template>

<style lang="scss" scoped>
::v-deep .v-btn:not(.v-btn--round).v-size--default {
	min-width: 38px;
}
.text-transform-none {
	text-transform: none;
}
</style>

