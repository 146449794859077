<script>
import SsrCarousel from 'vue-ssr-carousel'
import 'vue-ssr-carousel/index.css'
import modelBypassMixin from '@/utils/model-bypass-mixin'
import baseCarousel from '#/v-shop/vue-src/v-shop/common/comp_Carousel.vue'

export default {
	extends: baseCarousel,
}
</script>

<style scoped lang="scss">
::v-deep .v-icon.v-icon::after {
	width: unset;
}

::v-deep .ssr-carousel .ssr-carousel-back-button {
	left: 2%;
}

::v-deep .ssr-carousel .ssr-carousel-next-button {
	right: 2%;
}

// ::v-deep .ssr-carousel-track {
// 	padding: 0 !important;
// }

.carousel {
	&__arrow {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #ffffff47;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);

		&--bg,
		&--bg-mobile,
		&--bgHalfRounded-right,
		&--bgHalfRounded-right-mobile,
		&--bgHalfRounded-left,
		&--bgHalfRounded-left-mobile {
			border-radius: 50%;
		}

		&--bg:active,
		&--bg:hover,
		&--bgHalfRounded-left:active,
		&--bgHalfRounded-left:hover &--bgHalfRounded-right:active,
		&--bgHalfRounded-right:hover {
			box-shadow: 0 7px 16px 0 rgb(0 0 0 / 30%), 0 1px 3px 0 rgb(0 0 0 / 30%);
			border-radius: 50%;
		}
	}

	&__arrow-icon {
		font-size: 40px;
	}

	&__dots {
		background-color: rgba(255, 255, 255, 0.4);
	}

	&--desktop {
		width: max-content;
	}

	&__dots-icon {
		border-radius: 50%;
		width: 12px;
		height: 12px;
		background-color: var(--link);
		// border: 3px solid white;
		opacity: 0.3;
		margin: 3px;

		&--active {
			// background-color: white !important;
			// border: 2px solid #676767 !important;
			width: 32px;
			border-radius: 10px;
			opacity: 1;
		}

		&--mobile {
			margin: 2px;
			width: 12px;
			height: 12px;
		}
	}
}
</style>
