<script>
import background from '../assets/cybermonday/cybermonday-bg.jpg'
import logoSelma from '../assets/cybermonday/logo-selma-cybermonday.png'
import productsMobile from '../assets/cybermonday/cybermonday-productos-mobile.png'
import productsDesktop from '../assets/cybermonday/cybermonday-productos-desktop.png'
import discountImg from '../assets/cybermonday/cybermonday-descuento.png'
import brandsMobile from '../assets/cybermonday/cybermonday-marcas-mobile.png'
import brandsDesktop from '../assets/cybermonday/cybermonday-marcas-desktop.png'
import sorteoImg from '../assets/cybermonday/cybermonday-sorteo.png'
import logoCyber from '../assets/cybermonday/cybermonday-logo.png'

import countdownMixin from '../mixins/countdown-mixin'

export default {
	mixins: [countdownMixin('cybermonday/secondsLeft')],
	metaInfo() {
		return {
			title: 'Cybermonday',
			meta: [{ vmid: 'cy', name: 'description', content: 'Cybermonday' }],
		}
	},
	data() {
		return {
			// images
			background,
			logoSelma,
			productsMobile,
			productsDesktop,
			discountImg,
			brandsMobile,
			sorteoImg,
			logoCyber,
			brandsDesktop,

			showText: false,

			// form
			firstname: '',
			email: '',
			lastname: '',
			birthdate: '',
			activePicker: null,
			category: '',
			modal: false,
			validation: {},
			state: 'pending',
			loading: false,
			loadingCategories: true,
			availableCategories: [],

			minHeight: 377.5,
			fileUrl:
				'https://d2eebw31vcx88p.cloudfront.net/selmadigital/uploads/3a977274e8364e828257a4fa21f07d84009a0a9b.pdf',
		}
	},
	mounted() {
		if (!this.secondsLeft) return this.$router.replace('/cybermonday')

		this.$nextTick(() => {
			this.$shopApi.get({
				url: '/hotsale-categories',
				loading: (v) => (this.loadingCategories = v),
				onSuccess: ({ data }) => {
					this.availableCategories = data.categories
				},
			})

			const ref = this.$refs.form
			if (!ref) return
			const rect = ref.getBoundingClientRect()
			this.minHeight = rect.height - 24
		})
	},
	methods: {
		async submit() {
			const data = {
				firstname: this.firstname,
				email: this.email,
				lastname: this.lastname,
				birthdate: this.birthdate,
				category: this.category,
			}

			return await this.$shopApi.post({
				url: '/user-forms/cybermonday',
				data: { data },
				loading: (v) => {
					this.loading = v
				},
				onSuccess: () => {
					this.state = 'success'
				},
				onError: () => {
					this.state = 'error'
				},
				onValidation: ({ validation }) => (this.validation = validation),
			})
		},
	},
	watch: {
		secondsLeft() {
			if (!this.secondsLeft) return this.$router.replace('/cybermonday')
		},
	},
	trackPageView: true,
	cssVars: {
		selector: '.shop-app',
	},
	async resolveData(context, to, from, next) {
		const { ssr } = context
		if (ssr) {
			ssr.shouldCache = true
			return {}
		}
	},
}
</script>

<template>
	<div class="container-cybermonday" :class="{ 'container--desktop': $b.td }">
		<div class="py-3">
			<Container>
				<v-row>
					<v-col cols="12" md="6">
						<div class="d-flex justify-center justify-md-start">
							<div>
								<div class="title-area">
									<router-link :to="{ name: 'home' }" class="title-area__logo-selma">
										<Media aspectRatio="1" :src="logoSelma" />
									</router-link>
									<div class="title-area__title-container">
										<div>
											<div class="title-area__subtitle">Todo vuelve, vuelve</div>
											<div class="title-area__cyber-title">
												Cyber <br />
												Monday
											</div>
										</div>
									</div>
									<div class="title-area__dates-message__container">
										<div class="title-area__dates-message">4, 5 y 6 DE NOVIEMBRE</div>
									</div>
									<div class="title-area__logo-cyber">
										<Media :src="logoCyber" />
									</div>
									<CyberMonday-Star class="title-area__star title-area__star--1" />
									<CyberMonday-Star class="title-area__star title-area__star--2" />
									<CyberMonday-Star class="title-area__star title-area__star--3" />
								</div>
								<div :class="$bem('timer')">
									<div :class="[$bem('__container'), $b.d && $bem('__container--desktop')]">
										<div :class="$bem('__time-unit-container')">
											<div :class="$bem('__digits')">{{ days }}</div>
											<div :class="$bem('__unit')">DIAS</div>
										</div>
										<div class="font-4">:</div>
										<div :class="$bem('__time-unit-container')">
											<div :class="$bem('__digits')">{{ hours }}</div>
											<div :class="$bem('__unit')">HORAS</div>
										</div>
										<div class="font-4">:</div>
										<div :class="$bem('__time-unit-container')">
											<div :class="$bem('__digits')">{{ minutes }}</div>
											<div :class="$bem('__unit')">MINUTOS</div>
										</div>
										<div class="font-4">:</div>
										<div :class="$bem('__time-unit-container')">
											<div :class="$bem('__digits')">{{ seconds }}</div>
											<div :class="$bem('__unit')">SEGUNDOS</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div style="position: relative">
							<div class="products-image__container">
								<Media :src="$b.m ? productsMobile : productsDesktop" />
							</div>
						</div>
					</v-col>
					<v-col cols="12" md="6">
						<div class="form-area">
							<div :class="$bem('form')">
								<div
									:class="[$b.m && $bem('__container'), $b.td && $bem('__container--desktop')]"
									class="mx-auto"
								>
									<div class="form__fields">
										<div class="form__floating-menu">
											<div class="d-flex justify-end mb-n2">
												<div class="form-area__close pa-2">
													<CyberMonday-Close />
												</div>
											</div>
											<div class="form-area__text-top">
												¡Hacé que también vuelvan <br v-if="$b.m" />
												tus mejores épocas!
											</div>
										</div>
										<div class="form__playlist-raffle-container">
											<div class="form__raffle">
												<div>
													<div class="__text-small">¡PARTICIPÁ DEL</div>
													<div class="__text-big">sorteo</div>
													<div class="__text-small">
														POR UNA <br />
														GIFTCARD DE
													</div>
													<div class="__text-big">$100mil!</div>
												</div>
											</div>
											<div class="ml-5">
												<div class="playlist-info">Te regalamos una</div>
												<SafeLink
													to="https://open.spotify.com/playlist/1EbPdlwq4mdFmquOkvkUDW?si=m4J1H5jyTKqHBRxfZkvrIg"
												>
													<Button
														class="playlist-btn mt-1"
														color="#FBA7FF"
														style="color: #355dff !important"
														depressed
														:height="$b.m ? '50px' : '65px'"
													>
														<div class="d-flex align-center">
															<div class="playlist-btn__icon">
																<CyberMonday-PlaylistIcon />
															</div>
															<div class="playlist-btn__text">Playlist</div>
														</div>
													</Button>
												</SafeLink>
												<div class="playlist-info mt-2">
													con <b>todos</b> los éxitos de los <br v-if="$b.m" />
													2000 y una <br v-if="$b.d" />
													guía de <br v-if="$b.t" />
													SPA <br v-if="$b.m" />
													para hacer en casa.
												</div>
											</div>
										</div>
										<div v-if="state != 'success'" ref="form">
											<div class="form__fields-title">¡Completá con tus datos!</div>
											<v-row class="form__fields-container">
												<v-col cols="12" sm="6" md="6" class="py-1">
													<TextField
														class="form__field"
														v-model="firstname"
														label="NOMBRE*"
														:error-messages="validation.firstname"
													/>
												</v-col>
												<v-col cols="12" sm="6" md="6" class="py-1">
													<TextField
														class="form__field"
														v-model="lastname"
														label="APELLIDO*"
														:error-messages="validation.lastname"
													/>
												</v-col>
												<v-col cols="12" sm="6" md="6" class="py-1">
													<TextField
														class="form__field"
														v-model="email"
														label="MAIL*"
														:error-messages="validation.email"
													/>
												</v-col>
												<v-col cols="12" sm="6" md="6" class="py-1">
													<TextField
														class="form__field"
														v-model="birthdate"
														label="CUMPLEAÑOS*"
														type="date"
														:error-messages="validation.birthdate"
													/>
												</v-col>
												<v-col cols="12" class="py-1">
													<Select
														:menu-props="{ contentClass: 'cybermonday-categories-menu' }"
														:loading="loadingCategories"
														class="form__field form__field--select"
														outlined
														style="font-family: 'Popins', sans-serif !important"
														background-color="transparent"
														label="CATEGORÍA DE INTERÉS*"
														append-icon="mdi-chevron-down"
														:error-messages="validation.category"
														:items="availableCategories"
														v-model="category"
														color="#355DFF"
														solo
													/>
												</v-col>
											</v-row>
											<div class="d-flex justify-center pt-8 mb-n6">
												<Button
													color="#FFE479"
													:class="$bem('__submit-btn')"
													style="font-family: 'IBM-Plex-Mono', monospace !important"
													depressed
													:height="$b.m ? '50px' : '65px'"
													:loading="loading"
													@click="submit"
												>
													<div style="position: relative">
														<div class="d-flex align-center">
															<div>Suscribirme</div>
															<div>
																<CyberMonday-Speaker />
															</div>
														</div>
														<div :class="$bem('__submit-btn__pointer')">
															<CyberMonday-Pointer />
														</div>
													</div>
												</Button>
											</div>
										</div>
										<div
											v-else
											class="white--text text-center d-flex justify-center align-center"
											:style="{ 'min-height': `${minHeight}px` }"
										>
											<div>
												<div>
													<div class="pt-4 pb-3 font-5">Gracias por Suscribirte</div>
													Pronto te enviaremos todas nuestras ofertas
													<SafeLink :to="fileUrl">
														<Button
															class="mt-3"
															color="#FBA7FF"
															style="
																color: #355dff !important;
																font-family: 'IBM-Plex-Mono', monospace;
															"
															depressed
														>
															<div class="font-2">Descargá la guía de SPA</div></Button
														>
													</SafeLink>
												</div>
											</div>
										</div>
										<CyberMonday-Star class="form__fields__star form__fields__star--1" />
										<CyberMonday-Star class="form__fields__star form__fields__star--2" />
										<CyberMonday-Star
											class="form__fields__star form__fields__star--3"
											v-if="$b.m"
										/>
									</div>
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
			</Container>
			<div class="go-to-store">
				<SafeLink :to="$shop.getShopRoute()">VOLVER AL SITIO</SafeLink>
				<CyberMonday-Reload />
			</div>
			<Media :src="$b.m ? brandsMobile : brandsDesktop" class="brands-image" />
		</div>
		<div class="text-center white--text">
			<div>CYBER MONDAY</div>
			<div>VER {{ showText ? 'MENOS' : 'MÁS' }}</div>
			<div @click="showText = !showText">
				<CyberMonday-DropDown
					class="dropdown-icon"
					:class="{ 'dropdown-icon--open': showText, 'dropdown-icon--close': !showText }"
				/>
			</div>
		</div>
		<div
			v-if="showText"
			class="legal-disclaimer text-center mb-8 white--text"
			:class="
				$b.m
					? 'legal-disclaimer--mobile'
					: $b.t
					? 'legal-disclaimer--tablet'
					: 'legal-disclaimer--desktop'
			"
		>
			<div class="mb-3">CYBER MONDAY 2024</div>
			<div>Conocé las ofertas en dermocosmética y cuidado personal Cyber Monday 2024</div>
			<div>
				Selma te trae el mejor catálogo de ofertas en dermocosmética, cuidado personal, suplementos,
				electro y fragancias Cyber Monday 2024. Por eso, si estás buscando la sintonía perfecta entre
				productos de belleza y cuidado, nuestras ofertas en dermocosmética Cyber Monday 2024 son una
				oportunidad que no podés dejar pasar. Cyber Monday es la campaña de ventas online organizada por
				CACE (Cámara Argentina de Comercio Electrónico) que brinda increíbles ofertas y descuentos en las
				principales tiendas online de Argentina y que se desarrolla del 4 al 6 de Noviembre.
			</div>
			<div>
				En esta décima edición, tenemos una gran variedad de cremas, suplementos y fragancias para que
				puedas comprar en línea todos los productos que siempre deseaste para vos.
			</div>
			<div class="my-3">SUSCRIBITE</div>
			<div>
				Enterate antes que nadie las ofertas y los productos disponibles. Conseguí beneficios exclusivos
				para el próximo Cyber Monday en Selma Digital.
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@font-face {
	font-family: 'Big-Sexy-Sans';
	src: url('../assets/cybermonday/BigSexySansRegular.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'IBM-Plex-Mono';
	src: url('../assets/cybermonday/IBM_Plex_Mono/IBMPlexMono-Regular.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'IBM-Plex-Mono';
	font-weight: light;
	src: url('../assets/cybermonday/IBM_Plex_Mono/IBMPlexMono-Light.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'IBM-Plex-Mono';
	font-weight: bold;
	src: url('../assets/cybermonday/IBM_Plex_Mono/IBMPlexMono-Bold.ttf') format('truetype');
	font-display: swap;
}
@font-face {
	font-family: 'IBM-Plex-Mono';
	font-weight: medium;
	src: url('../assets/cybermonday/IBM_Plex_Mono/IBMPlexMono-Medium.ttf') format('truetype');
	font-display: swap;
}

@mixin font-poppins {
	font-family: 'Poppins', sans-serif;
}

@mixin font-ibm {
	font-family: 'IBM-Plex-Mono', monospace !important;
}

@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin blue-border-shadow($border-width: 1px) {
	border: $border-width solid #355dff;
	box-shadow: -6px 6px 0px 0px #355dff;
}

.poppins {
	@include font-poppins;
}

.relative {
	position: relative;
}

.container-cybermonday {
	background: linear-gradient(360deg, #f866ff 0%, #44b1ff 100%);
	height: 100%;
}

.title-area {
	position: relative;
	&__subtitle {
		@include font-ibm;
		font-weight: 600;
		color: white;
		padding-top: 24px;
		margin: 0 0 4px 38px;
	}
	&__title-container {
		@include flex-center;
		position: relative;
		z-index: 2;
	}
	&__cyber-title {
		text-align: left;
		font-family: 'Big-Sexy-Sans', sans-serif;
		font-size: 3.6rem;
		line-height: 3.2rem;
		color: #ffe479;
		text-shadow: -8px 8px #355dff;
		-webkit-text-stroke: 1px #355dff;
	}
	&__logo-cyber {
		width: 60px;
		position: absolute;
		top: 24%;
		right: 8%;
	}
	&__logo-selma {
		width: 55px;
		z-index: 1;
		position: absolute;
		top: 4px;
		left: 0;
	}
	&__dates-message__container {
		@include flex-center;
		padding: 0 24px;
	}
	&__dates-message {
		@include font-poppins;
		@include blue-border-shadow;
		width: 100%;
		max-width: 400px;
		font-size: 1.2rem;
		text-align: center;
		font-weight: bold;
		letter-spacing: 2px;
		color: white;
		background: #f866ff;
		border-radius: 50px;
	}
	&__star {
		position: absolute;
		&--1 {
			right: 94px;
			top: 14px;
			width: 20px;
		}
		&--2 {
			width: 28px;
			bottom: 28%;
			right: 0%;
		}
		&--3 {
			width: 31px;
			bottom: 6%;
			left: 0;
		}
	}
}

.timer {
	@include font-poppins;
	padding-top: 16px;
	display: flex;
	justify-content: center;

	&__time-unit-container {
		text-align: center;
		padding: 12px;
	}

	&__container {
		display: flex;
		align-items: center;
		text-align: center;
		font-weight: bold;
		color: white;

		&--desktop {
			width: 100%;
			max-width: 420px;
			justify-content: space-around;
		}
	}
	&__digits {
		font-size: 3.2rem;
		line-height: 3.1rem;
		font-weight: 600;
	}
	&__unit {
		font-size: 0.9rem;
	}
}

.form-area {
	margin-bottom: 24px;
	&__text-top {
		@include font-ibm;
		@include blue-border-shadow(2px);
		text-align: center;
		font-size: 14px;
		color: white;
		background: linear-gradient(270deg, #f866ff 0%, #44b1ff 100%);
		border-radius: 15px;
	}
	&__close {
		background: #fba7ff;
		border: 1px solid #355dff;
		box-shadow: -3px 3px 0px 0px #355dff;
		border-radius: 5px;
	}
}

.form {
	&__container {
		padding: 16px 0;

		&--tablet {
			max-width: 506px;
		}
	}

	&__floating-menu {
		width: 90%;
		position: absolute;
		top: -60px;
		right: 4px;
	}

	&__playlist-raffle-container {
		color: white;
		margin: 12px 0 0 0;
		text-align: center;
		display: flex;
		position: relative;
	}

	&__raffle {
		@include flex-center;
		@include blue-border-shadow;
		margin-left: -32px;
		width: 132px;
		height: 132px;
		border-radius: 200px;
		background: linear-gradient(270deg, #f866ff 0%, #44b1ff 100%);
		transform: rotate(-10deg);

		.__text-small {
			@include font-poppins;
			font-size: 8px;
			line-height: 0.95rem;
			font-weight: 600;
		}
		.__text-big {
			@include font-ibm;
			font-size: 22px;
			font-weight: 700;
		}
	}

	.playlist-btn {
		padding: 0 26px !important;
		border-radius: 12px;
		box-shadow: -8px 8px 0px 0px #355dff;
		border: 2px solid #355dff !important;
		& ::v-deep .v-btn__content {
			font-size: 1.3rem;
			@include font-ibm;
			font-weight: light;

			@media (min-width: 900px) {
				font-size: 1.6rem;
			}
		}

		&__icon {
			padding-top: 6px;
			margin-right: 8px;
		}

		&__text {
			@include font-ibm;
			font-weight: light;
		}
	}
	.playlist-info {
		@include font-poppins;
	}
	&__fields-title {
		@include font-poppins;
		color: white;
		text-align: center;
		font-weight: bold;
		padding: 12px 0 16px 0;
		font-size: 1.2rem;
		line-height: 1.7rem;
	}
	&__fields {
		@include blue-border-shadow;
		margin: 24px 0 0 0;
		padding: 24px 16px 0 16px;
		position: relative;
		background: #f866ff;
		border-radius: 20px;
		&__star {
			position: absolute;
			&--1 {
				top: 50%;
				left: -16px;
				width: 30px;
			}
			&--2 {
				top: 30%;
				right: -13px;
				width: 31px;
			}
			&--3 {
				bottom: 26%;
				right: -11px;
				width: 22px;
			}
		}
	}

	&__field {
		padding: 16px 0;
		margin: 16px 0;
		&--select {
			@include blue-border-shadow;
			padding: 0;
			max-width: 270px;
			margin: 18px auto 24px auto !important;
			border-radius: 100px !important;
			& ::v-deep i.mdi.v-icon {
				color: #355dff;
			}
		}
	}

	&__submit-btn {
		font-size: 1.3rem;
		padding: 0 64px !important;
		border: 2px solid #355dff !important;
		box-shadow: -3px 3px #355dff !important;

		color: #355dff !important;
		border-radius: 100px !important;

		&__pointer {
			position: absolute;
			top: 84%;
			right: -58%;
		}
	}
}

::v-deep label {
	@include font-poppins;
}
::v-deep input {
	@include font-poppins;
	margin-left: 8px;
}
::v-deep input[type='date']::-webkit-calendar-picker-indicator {
	opacity: 0;
}

.container--desktop {
	padding-top: 34px;

	& .title-area {
		&__logo-selma {
			width: 84px;
			top: -3%;
			left: -14%;
		}

		&__subtitle {
			margin: 0 0 18px 38px;
			font-size: 1.4rem;
		}

		&__cyber-title {
			font-size: 5.2rem;
			line-height: 4.2rem;
			-webkit-text-stroke: 1px #355dff;
		}

		&__logo-cyber {
			width: 80px;
			position: absolute;
			top: 21%;
			right: 0;
		}

		&__dates-message__container {
			padding: 0;
		}

		&__dates-message {
			font-size: 1.2rem;
		}

		&__star {
			&--1 {
				top: 6%;
				right: 24%;
				width: 30px;
			}

			&--2 {
				width: 24px;
				right: -7%;
				bottom: 14%;
			}

			&--3 {
				left: -11%;
			}
		}
	}

	& .form-area {
		margin-top: 46px !important;

		& .form {
			&__floating-menu {
				width: 100%;
				right: 0;
				left: 5%;
			}
			&__submit-btn {
				width: 100%;
				max-width: 85%;
				&__pointer {
					position: absolute;
					top: 84%;
					right: -100%;
				}
			}

			&__fields-title {
				font-size: 1.4rem;
				line-height: 1.9rem;
			}

			&__fields__star {
				&--1 {
					top: 65%;
					left: -29px;
					width: 50px;
					height: 60px;
				}
				&--2 {
					width: 50px;
					height: 60px;
					right: -25px;
				}
			}

			&__field {
				margin: 4px 0;
				padding: 11px 0;
				&--select {
					margin: 0;
					padding: 0;
				}
			}
		}

		&__close {
			margin-right: 32px;
		}

		&__text-top {
			font-size: 20px;
			padding: 12px 0;
		}
	}

	& .form__raffle {
		position: absolute;
		left: -42px;
		width: 146px;
		height: 146px;
		top: 0;

		.__text-small {
			font-size: 11px;
		}
		.__text-big {
			font-size: 24px;
		}
	}

	& .form__fields-container {
		padding: 0 32px;
	}
	& .form__playlist-raffle-container {
		justify-content: center;
		align-items: center;
	}
	& .playlist-btn {
		width: 100%;
		max-width: 246px;
	}
	& .playlist-info {
		font-size: 1.4rem;
		line-height: 1.9rem;
	}
	& .brands-image {
		padding: 0 32px;
	}

	& .products-image__container {
		position: absolute;
		width: 110%;
		right: 0%;
	}

	@media screen and (max-width: 820px) {
		& .form__container--desktop {
			max-width: 84%;
		}
	}

	@media screen and (max-width: 768px) {
		& .products-image__container {
			position: static;
			width: 100%;
		}
		& .form__container--desktop {
			max-width: 84%;
		}
		& .title-area {
			&__logo-selma {
				left: -14%;
			}
			&__logo-cyber {
				right: 0%;
			}
			&__star {
				&--1 {
					right: 24%;
				}

				&--2 {
					right: -8%;
				}

				&--3 {
					left: -12%;
				}
			}
		}
	}
}

.cybermonday-categories-menu .v-list {
	@include font-poppins;
	background-color: #fba7ff !important;
	& ::v-deep .v-list-item__title {
		color: #355dff !important;
	}
}

.go-to-store {
	@include font-poppins;
	margin: 32px 0;
	text-align: center;
	& a {
		margin-right: 4px;
		color: white !important;
		text-decoration: underline;
	}
}

.dropdown-icon {
	&--open {
		transform: rotate(180deg);
	}
	&--close {
		transform: rotate(0deg);
	}
}

.legal-disclaimer {
	margin: auto;
	&--mobile {
		padding: 0 16px 0 16px;
	}
	&--tablet {
		max-width: 80%;
	}
	&--desktop {
		max-width: 55%;
	}
}

::v-deep .v-input {
	background: #fba7ff;
	border-radius: 8px;
	border-bottom: 1px solid #355dff;
}
::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
	border-color: transparent !important;
}
::v-deep .v-text-field > .v-input__control > .v-input__slot:after {
	background-color: transparent !important;
	transform: scale(0) !important;
	-webkit-transform: scale(0) !important;
}
::v-deep .theme--light.v-input input {
	color: #355dff !important;
}
::v-deep .theme--light.v-label {
	color: #355dff !important;
	padding-left: 8px;
}

::v-deep .v-text-field__suffix {
	font-size: 13px;
	color: rgba(0, 0, 0, 0.87) !important;
}

::v-deep .v-label {
	width: 100% !important;
	max-width: unset !important;
}
::v-deep .theme--light.v-select .v-select__selections {
	color: #355dff !important;
}
</style>

