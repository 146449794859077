<script>
import BaseImagesSlider from '#/v-shop/vue-src/v-shop/dynamic-pages-components/comp_dpc-ImagesSliderV2.vue'

export default {
	extends: BaseImagesSlider,
	computed: {
		carouselProps() {
			return {
				showArrows: true,
			}
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep .carousel__arrow {
	background-color: #ffffff00 !important;
	border-radius: 50%;
}

::v-deep .ssr-carousel-mask > div {
	padding: 0;
}
</style>
