<script>
export default {
	props: ['ProductPage'],
	hookZone: ['after--brand-sku'],
	computed: {
		isWarehouse() {
			if (this.$ssr) return false
			return localStorage.getItem('isWarehouse') || false
		},
	},
}
</script>

<template>
	<ClientSideOnly>
		<div v-if="isWarehouse" class="base--text">
			<div class="mr-2">
				Código de Barras:
				<span class="font-weight-bold">{{ ProductPage.selectedVariant.ean || 'N/D' }}</span>
			</div>
			<div>
				Proveedor:
				<span class="font-weight-bold">{{ ProductPage.product.supplier || 'N/D' }}</span>
			</div>
		</div>
	</ClientSideOnly>
</template>

<style></style>
