<script>
export default {
	props: {
		products: Array,
		listId: String,
		offer: Object,
		loop: { type: Boolean, default: false },
		dotContainerFullWidthMobile: { type: Boolean, default: false },
		arrowsBgHalfRounded: { type: Boolean, default: false },
	},
	computed: {
		rows() {
			let arr = this.products
			let middle = Math.floor(arr.length / 2)
			let arr1 = []
			let arr2 = []
			if (arr.length) {
				arr1 = arr.slice(0, middle)
				arr2 = arr.slice(middle)
			}
			console.log([arr1, arr2])
			return [arr1, arr2]
		},
	},
	mounted() {
		if (this.products.length && this.listId) {
			this.$eventer().trigger('product-list:view', {
				products: this.products,
				listId: this.listId,
			})
		}
	},
}
</script>

<template>
	<Carousel
		:dotContainerFullWidthMobile="dotContainerFullWidthMobile"
		class="products-carousel"
		:loop="loop"
		:showDots="$b.mt"
		:arrowsBgHalfRounded="arrowsBgHalfRounded"
		v-bind="{
			...$b.props({
				slidesPerPage: { m: 2, t: 3, d: 4 },
				gutter: { m: 5 },
				showArrows: { d: true },
				center: { m: true },
			}),
			...this.$attrs,
		}"
		:id="listId"
	>
		<div class="d-flex">
			<div v-for="(row, index) of rows" :key="index" class="d-flex">
				<div class="d-flex justify-center" v-for="(product, i) of row" :key="i">
					<ProductCard
						:product="product"
						:list-id="listId"
						:list-index="i"
						:offer="offer"
						:small="$b.m"
					/>
				</div>
			</div>
		</div>
	</Carousel>
</template>
