<script>
import footer from '#/v-shop-base-layout/vue-src/v-shop/footer/comp_Footer-Bottom'
export default {
	extends: footer,
	data() {
		return {
			copyrightText: this.$srv('FooterBottom.copyrightText') || '',
		}
	},
	cssVars: {
		selector: '.footer',
	},
}
</script>
<template>
	<div class="footer primary base w100" v-if="copyrightText">
		<Container>
			<v-row align="center" :class="alignment">
				<v-col
					cols="12"
					md="8"
					class="primary"
					:class="[{ 'px-1': $b.d }, alignment]"
					v-html="copyrightText"
				>
				</v-col>
			</v-row>
		</Container>
	</div>
</template>
<style lang="scss" scoped>
.footer {
	border-top: 1px solid var(--secondarytext);
}
</style>

