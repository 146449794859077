<script>
export default {
	props: ['Footer'],
	hookZone: ['top'],
	cssVars: {
		selector: '.footer',
	},
}
</script>

<template>
	<div class="d-flex justify-center" :style="`background:${$vars.base}`">
		<Container>
			<Footer-Top :data="Footer.dataTop" class="py-6" />
		</Container>
	</div>
</template>

<style></style>
