<script>
export default {
	props: { ShopList: Object },
	hookZone: ['possible-filters'],
	computed: {
		possibleFilters() {
			return {
				...this.ShopList.shop.possibleFilters,
				sortBy: this.ShopList.shop.possibleFilters.sortBy.filter(
					(i) => i.value !== 'name-low-to-high' && i.value !== 'name-high-to-low'
				),
			}
		},
	},
}
</script>

<template>
	<ShopFilters
		class="pr-2"
		h1-title
		:title="ShopList.shop.context.title"
		:total-results="ShopList.shop.pagination.itemsLength"
		:applied-filters="ShopList.shop.appliedFilters"
		:possible-filters="possibleFilters"
		v-intersect="ShopList.onIntersect"
	/>
</template>
