<script>
import BaseAutocompleteSearchField from '#/v-shop-base-layout/vue-src/v-shop/appbar/comp_AutocompleteSearchField.vue'

export default {
	components: {
		BaseAutocompleteSearchField,
	},
	data() {
		return {
			topSearches: null,
			topSearchesLoading: false,
		}
	},
	computed: {
		isAlgolia() {
			return this.$srv('isAlgolia')
		},
		custom() {
			if (!this.isAlgolia) return null
			return {
				fetchQuickSearchResults: this.fetchQuickSearchResults.bind(this),
			}
		},
	},
	methods: {
		async fetchQuickSearchResults(searchQuery) {
			let { success, data } = await this.$shopApi.post({
				url: '/algolia-search',
				data: {
					search: searchQuery,
				},
			})
			if (success) {
				return {
					products: data.products,
					brands: data.brands,
					categories: data.categories,
					suggestions: data.suggestions,
				}
			}
		},
		async getTopSearches() {
			if (!this.isAlgolia || this.topSearches) return
			this.topSearches = []
			this.$shopApi.get({
				url: '/algolia-top-searches',
				loading: (v) => (this.topSearchesLoading = v),
				onSuccess: ({ data }) => {
					this.topSearches = data.topSearches
				},
			})
		},
		sliceSuggestions(arr) {
			return this.$b.m ? arr.slice(0, 2) : arr
		},
	},
}
</script>

<template>
	<BaseAutocompleteSearchField
		:custom="custom"
		@visible:change="$event && getTopSearches()"
		v-bind="$attrs"
		v-on="$listeners"
	>
		<template #before-products="{ results, open, loading, searchQuery }">
			<div v-if="!loading && searchQuery && results.suggestions && results.suggestions.length">
				<v-subheader>Sugerencias de búsqueda</v-subheader>
				<v-list-item-group>
					<v-list-item
						v-for="(search, index) of sliceSuggestions(results.suggestions)"
						:key="`suggestion-${index}`"
						@click="open(search)"
						class="common-link"
					>
						<v-list-item-icon>
							<v-icon>mdi-magnify</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							{{ search }}
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</div>
			<div v-if="$b.td && !loading && searchQuery && results.categories && results.categories.length">
				<v-subheader>Categorías relacionadas</v-subheader>
				<div class="d-flex align-center flex-wrap px-4">
					<v-chip
						v-for="(category, index) of results.categories"
						:key="`category-${index}`"
						@click="$shop.search(searchQuery, { categories: category.familyUrlNames })"
						class="autocomplete-chip mr-2 mb-2"
					>
						{{ category.name }}
					</v-chip>
				</div>
			</div>
			<div v-if="$b.td && !loading && searchQuery && results.brands && results.brands.length">
				<v-subheader>Marcas relacionadas</v-subheader>
				<div class="d-flex align-center flex-wrap px-4">
					<v-chip
						v-for="(brand, index) of results.brands"
						:key="`brand-${index}`"
						@click="$shop.search(searchQuery, { brand: brand.urlName })"
						class="autocomplete-chip mr-2 mb-2"
					>
						{{ brand.name }}
					</v-chip>
				</div>
			</div>
		</template>
		<template #after-products="{ results, loading, searchQuery, searchHistory, open }">
			<div v-if="$b.m && !loading && searchQuery && results.categories && results.categories.length">
				<v-subheader>Categorías relacionadas</v-subheader>
				<div class="d-flex align-center flex-wrap px-4">
					<v-chip
						v-for="(category, index) of results.categories"
						:key="`category-${index}`"
						@click="$shop.search(searchQuery, { categories: category.familyUrlNames })"
						class="autocomplete-chip mr-2 mb-2"
					>
						{{ category.name.split(' > ').slice(-2).join(' > ') }}
					</v-chip>
				</div>
			</div>
			<div v-if="$b.m && !loading && searchQuery && results.brands && results.brands.length">
				<v-subheader>Marcas relacionadas</v-subheader>
				<div class="d-flex align-center flex-wrap px-4">
					<v-chip
						v-for="(brand, index) of results.brands"
						:key="`brand-${index}`"
						@click="$shop.search(searchQuery, { brand: brand.urlName })"
						class="autocomplete-chip mr-2 mb-2"
					>
						{{ brand.name }}
					</v-chip>
				</div>
			</div>
			<div v-if="topSearches">
				<v-subheader>Las búsquedas más populares</v-subheader>
				<div v-if="topSearchesLoading" class="pa-4">
					<v-progress-circular indeterminate width="2" size="26" />
				</div>
				<v-list-item-group>
					<v-list-item
						dense
						v-for="(search, index) of topSearches"
						:key="index"
						@click="open(search)"
						class="common-link"
					>
						<v-list-item-icon>
							<v-icon>mdi-trending-up</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							{{ search }}
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</div>
		</template>
	</BaseAutocompleteSearchField>
</template>

