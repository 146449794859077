<script>
import bgMobile from '../assets/hotsale/mobile/landing-mobile-fondo-2.png'
import bgDesktop from '../assets/hotsale/desktop/landing-desktop-fondo.jpg'
import logoSelmaMobile from '../assets/cybermonday/logo-selma.png'
import produMobile from '../assets/hotsale/mobile/landing-mobile-produs.png'
import hotsaleMobile from '../assets/hotsale/mobile/hotsale-mobile.png'
import brandsMobile from '../assets/hotsale/mobile/brands-mobile.png'
import countdownMixin from '../mixins/countdown-mixin'
import logoHotsaleMobile from '../assets/hotsale/mobile/landing-mobile-logo.png'
import flameMobile from '../assets/hotsale/mobile/flama.png'
import logoHotsaleDesktop from '../assets/hotsale/desktop/hotsale-desktop.jpg'
import brandsDesktop from '../assets/hotsale/desktop/brands-desktop.png'

import desktopProductsLeft from '../assets/hotsale/desktop/landing-desktop-produs-1.png'
import desktopProductsRight from '../assets/hotsale/desktop/landing-desktop-produs-2.png'

import paymentsDesktopLeft from '../assets/hotsale/desktop/landing-desktop-cucarda-cuotas.png'
import discountDesktopRight from '../assets/hotsale/desktop/landing-desktop-cucarda-fuego.png'

export default {
	mixins: [countdownMixin('hotsale/secondsLeft')],
	metaInfo() {
		return {
			title: 'Hot Sale',
			meta: [{ vmid: 'hs', name: 'description', content: 'Hot Sale' }],
		}
	},
	data() {
		return {
			hotsaleColor: '#fc5226',

			// images
			bgMobile,
			bgDesktop,
			logoSelmaMobile,
			produMobile,
			hotsaleMobile,
			brandsMobile,
			logoHotsaleMobile,
			flameMobile,
			logoHotsaleDesktop,
			brandsDesktop,
			desktopProductsLeft,
			desktopProductsRight,
			paymentsDesktopLeft,
			discountDesktopRight,

			// form
			firstname: '',
			email: '',
			lastname: '',
			birthdate: '',
			category: '',
			activePicker: null,
			modal: false,
			validation: {},
			state: 'pending',
			loading: false,
			loadingCategories: false,

			showLegalText: false,
			availableCategories: [],
		}
	},
	created() {
		this.$shopApi.get({
			url: '/hotsale-categories',
			loading: (v) => (this.loadingCategories = v),
			onSuccess: ({ data }) => {
				this.availableCategories = data.categories
			},
		})
	},
	methods: {
		async submit() {
			const data = {
				firstname: this.firstname,
				email: this.email,
				lastname: this.lastname,
				birthdate: this.birthdate,
				category: this.category,
			}

			return await this.$shopApi.post({
				url: '/user-forms/hotsale',
				data: { data },
				loading: (v) => {
					this.loading = v
				},
				onSuccess: () => {
					this.state = 'success'
				},
				onError: () => {
					this.state = 'error'
				},
				onValidation: ({ validation }) => (this.validation = validation),
			})
		},
	},
	watch: {
		secondsLeft() {
			if (!this.secondsLeft) return this.$router.replace({ name: 'hotsale' })
		},
	},
	mounted() {
		if (!this.secondsLeft) return this.$router.replace({ name: 'hotsale' })
	},
	trackPageView: true,
	cssVars: {
		selector: '.shop-app',
	},
}
</script>

<template>
	<div
		class="hotsale-container overflow-hidden"
		:class="
			$b.d
				? 'hotsale-container--desktop'
				: $b.t
				? 'hotsale-container--tablet'
				: 'hotsale-container--mobile'
		"
		:style="`background-image: url(${
			$b.m ? bgMobile : bgDesktop
		}); background-repeat: no-repeat; height: 100%`"
	>
		<div class="main py-3 h100" style="position: relative">
			<template v-if="$b.d">
				<Media class="payments-desktop-left" :src="paymentsDesktopLeft" />
				<Media class="products-desktop-left" :src="desktopProductsLeft" />
				<Media class="discount-desktop-right" :src="discountDesktopRight" />
				<Media class="products-desktop-right" :src="desktopProductsRight" />
			</template>
			<div class="text-center">
				<router-link
					:to="{ name: 'home' }"
					class="d-inline-block"
					:style="$b.m ? 'width: 68px' : 'width: 88px'"
				>
					<Media aspectRatio="1" :src="logoSelmaMobile" />
				</router-link>
			</div>
			<div class="timer-logo-container mx-auto">
				<div class="d-flex justify-center pt-3" style="position: relative">
					<Media :src="logoHotsaleMobile" :aspectRatio="126 / 113" class="logo-hotsale-mobile" />
					<Media
						:aspectRatio="$b.m ? 585 / 253 : 586 / 251"
						:src="$b.m ? hotsaleMobile : logoHotsaleDesktop"
						:width="$b.m ? '100%' : '100%'"
					/>
				</div>
				<div class="timer d-flex justify-center" :class="$bem('timer')">
					<div
						class="d-flex white--text align-center font-weight-bold"
						:class="[$bem('__container'), $b.d && $bem('__container--desktop')]"
					>
						<div :class="$bem('__time-unit-container')" class="pa-3 text-center">
							<div :class="$bem('__digits')">{{ days }}</div>
							<div :class="$bem('__unit')" class="poppins-medium">DIAS</div>
						</div>
						<div class="font-4">:</div>
						<div :class="$bem('__time-unit-container')" class="pa-3 text-center">
							<div :class="$bem('__digits')">{{ hours }}</div>
							<div :class="$bem('__unit')" class="poppins-medium">HORAS</div>
						</div>
						<div class="font-4">:</div>
						<div :class="$bem('__time-unit-container')" class="pa-3 text-center">
							<div :class="$bem('__digits')">{{ minutes }}</div>
							<div :class="$bem('__unit')" class="poppins-medium">MINUTOS</div>
						</div>
						<div class="font-4">:</div>
						<div :class="$bem('__time-unit-container')" class="pa-3 text-center">
							<div :class="$bem('__digits')">{{ seconds }}</div>
							<div :class="$bem('__unit')" class="poppins-medium">SEGUNDOS</div>
						</div>
					</div>
				</div>
			</div>
			<div class="color-violet poppins-semibold font-2 px-4 py-8 pb-4 text-center text-uppercase">
				¡Suscribite y recibí las mejores ofertas <br />
				antes que nadie!
			</div>
			<div :class="$bem('form')" v-if="state !== 'success'">
				<div
					:class="[
						$b.m && $bem('__container'),
						$b.t && $bem('__container--tablet'),
						$b.d && $bem('__container--desktop'),
					]"
					class="mx-auto"
				>
					<form @submit.prevent="submit">
						<v-row class="mb-4">
							<v-col cols="12" sm="6" md="6" class="py-1">
								<TextField
									class="py-3"
									v-model="firstname"
									:error-messages="validation.firstname"
									label="NOMBRE*"
									:color="hotsaleColor"
								/>
							</v-col>
							<v-col cols="12" sm="6" md="6" class="py-1">
								<TextField
									class="py-3"
									v-model="lastname"
									label="APELLIDO*"
									:error-messages="validation.lastname"
									:color="hotsaleColor"
								/>
							</v-col>
							<v-col cols="12" sm="6" md="6" class="py-1">
								<TextField
									class="py-3"
									v-model="email"
									label="MAIL*"
									:error-messages="validation.email"
									:color="hotsaleColor"
								/>
							</v-col>
							<v-col cols="12" sm="6" md="6" class="py-1">
								<TextField
									class="py-3"
									v-model="birthdate"
									label="CUMPLEAÑOS*"
									type="date"
									:error-messages="validation.birthdate"
									:color="hotsaleColor"
								/>
							</v-col>
							<v-col cols="12" class="py-1">
								<Select
									:menu-props="{ contentClass: 'hotsale-categories-menu' }"
									:loading="loadingCategories"
									class="mx-auto"
									style="max-width: 320px"
									outlined
									background-color="transparent"
									label="CATEGORÍA DE INTERÉS*"
									:error-messages="validation.category"
									:items="availableCategories"
									v-model="category"
									:color="hotsaleColor"
									solo
								/>
							</v-col>
						</v-row>

						<div class="d-flex justify-center pt-8 pb-4">
							<div style="position: relative; width: 100%; max-width: 350px">
								<Button
									color="#ff4b10"
									style="color: #fff !important"
									:class="$bem('__button')"
									depressed
									type="submit"
									width="100%"
									:height="$b.td ? '60px' : ''"
									rounded
									:loading="loading"
									:x-large="$b.td"
								>
									<span>SUSCRIBIRME</span>
								</Button>
								<Media class="flame" :src="flameMobile" :aspectRatio="94 / 95" />
							</div>
						</div>
						<div class="text-center">
							<Button depressed text @click="$router.replace({ name: 'home' })">
								<div class="color-main font-1">
									VOLVER AL SITIO <v-icon :color="hotsaleColor" size="15px">mdi-reload</v-icon>
								</div>
							</Button>
						</div>
					</form>
				</div>
			</div>
			<div v-else>
				<div class="color-violet poppins-regular font-2 px-4 pb-4 text-center">
					<div class="pt-4 pb-6 font-5">Gracias por Suscribirte</div>
					<div>Pronto te enviaremos todas nuestras ofertas</div>
					<router-link :to="$shop.getShopRoute()">
						<Button
							class="mt-4"
							:color="hotsaleColor"
							style="color: #fff !important; border-radius: 10px"
							depressed
							:height="$b.m ? '50px' : '65px'"
						>
							Volver a la tienda
						</Button>
					</router-link>
				</div>
			</div>
			<div
				v-if="$b.mt"
				class="d-flex justify-center align-center mx-sm-n5"
				:class="{ 'flex-column': $b.m }"
			>
				<Media
					:src="produMobile"
					:aspectRatio="725 / 359"
					width="100%"
					:class="$b.t && 'mt-n16'"
					class="products-mobile"
				/>
			</div>
			<div>
				<Media
					class="mx-auto"
					:src="$b.m ? brandsMobile : brandsDesktop"
					:aspectRatio="$b.m ? 614 / 165 : 705 / 81"
					:width="$b.m ? '100%' : '60%'"
				/>
			</div>
			<div class="d-flex justify-center mt-3 font-0">
				<div class="text-center color-main">
					<div class="font-1 poppins-bold">HOT SALE 2024</div>
					<div v-show="showLegalText" class="pa-2 poppins-regular">
						<div>Conocé las ofertas en dermocosmética y cuidado personal Hot Sale 2024</div>
						<div>
							Selma Digital te trae el mejor catálogo de ofertas en dermocosmética, suplementos,
							fragancias, electro belleza, electro salud y cuidado personal en Hot Sale 2024.
						</div>
						<div>
							Por eso, si estás buscando la sintonía perfecta entre productos de belleza y cuidado,
							nuestras ofertas Hot Sale 2024 son una oportunidad que no podes dejar pasar. Hot Sale es
							la campaña de ventas online organizada por CACE (Cámara Argentina de Comercio
							Electrónico) que brinda increíbles ofertas y descuentos en las principales tiendas online
							de Argentina y que se desarrolla del 13 al 15 de Mayo.<br />
							En esta décima edición, tenemos una gran variedad de cremas, fragancias, suplementos,
							productos de electro y cuidado personal para que puedas comprar en línea todos lo que
							siempre deseaste para vos.
						</div>
						<div>SUSCRIBITE</div>
						<div>
							Enterate antes que nadie las ofertas y los productos disponibles. Conseguí beneficios
							exclusivos para el próximo Hot Sale en Selma Digital.
						</div>
					</div>
					<div class="poppins-medium">VER {{ showLegalText ? 'MENOS' : 'MÁS' }}</div>
					<div>
						<v-icon color="#fc5226" @click="showLegalText = !showLegalText">
							mdi-chevron-{{ showLegalText ? 'up' : 'down' }}
						</v-icon>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Protest+Strike&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$main-color: #fc5226;

// .products-mobile {
// 	margin-right: -20px;

// 	@media (min-width: 300px) {
// 		margin-right: -26px;
// 	}

// 	@media (min-width: 768px) {
// 		margin-right: -45px;
// 	}
// }

.flame {
	position: absolute !important;
	left: -17px;
	bottom: -8px;
	width: 28% !important;
}

.logo-hotsale-mobile {
	position: absolute !important;
	z-index: 2;
	top: -45px;
	right: -4px;
	width: 27% !important;

	@media (min-width: 300px) {
		top: -45px;
		right: -30px;
	}
	@media (min-width: 768px) {
		top: -18%;
		right: -25%;
	}
}

.timer-logo-container {
	padding: 0;
	max-width: unset;
	@media (min-width: 300px) {
		max-width: 90%;
	}
	@media (min-width: 700px) {
		max-width: 66%;
	}
	@media (min-width: 960px) {
		max-width: 44%;
	}
	@media (min-width: 300px) {
		padding: 0 16px 0 16px;
	}
}

.payments-desktop-left {
	position: absolute !important;
	top: 38%;
	left: 2%;
	width: 18% !important;

	@media (min-width: 1600px) {
		top: 35%;
		left: 2%;
	}
}
.products-desktop-left {
	position: absolute !important;
	top: 58%;
	left: -8%;
	width: 32% !important;
}
.discount-desktop-right {
	position: absolute !important;
	top: 32%;
	right: -6%;
	width: 27% !important;
}
.products-desktop-right {
	position: absolute !important;
	top: 61%;
	right: -15%;
	width: 39% !important;
}

.hotsale-categories-menu .v-list {
	background-color: #f9d7bc !important;
	& ::v-deep .v-list-item__title {
		color: $main-color !important;
	}
}

::v-deep fieldset {
	color: $main-color !important;
}
::v-deep .v-text-field--outlined {
	border-radius: 31px;
	border-width: 2px;
}
.text-container {
	margin: auto;
	&--mobile {
		padding: 0 16px 0 16px;
	}
	&--tablet {
		max-width: 80%;
	}
	&--desktop {
		max-width: 55%;
	}
}

.color-main {
	color: $main-color;
}

.color-purple {
	color: $main-color;
}

.color-violet {
	color: $main-color;
}

.dates-message {
	letter-spacing: 2px;
}

.hotsale-container {
	padding: 0 7px 0 7px;
	@media (min-width: 300px) {
		padding: 0 16px 0 16px;
	}
	@media (min-width: 768px) {
		padding: 0 48px 0 48px;
	}

	&--tablet {
		height: 100vh;
	}
	&--desktop {
		background-size: cover;
	}
}

::v-deep .theme--light.v-input input {
	color: rgba(0, 0, 0, 0.62) !important;
}

.timer {
	background-color: #ff4b10;

	&__container {
		&--desktop {
			width: 100%;
			max-width: 420px;
			justify-content: space-around;
		}

		&__time-unit-container {
		}
	}
	&__digits {
		font-size: 1.8rem;
		line-height: 2.1rem;

		@media (min-width: 300px) {
			font-size: 2rem;
			line-height: 3.1rem;
		}
		@media (min-width: 768px) {
			font-size: 3rem;
		}
	}
	&__unit {
		font-size: 0.7rem;
	}
}

.main {
	background: #f9d7bc;

	@media (min-width: 900px) {
		max-width: 70%;
		margin: auto;
	}
}
.form {
	color: $main-color;

	&__button {
		font-size: 1rem;
		box-shadow: 5px 6px 0px 0px #ffb356, 0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0) !important;
	}

	& ::v-deep {
		.v-text-field {
			& > .v-input__control > .v-input__slot::before {
				border-color: $main-color !important;
				color: $main-color !important;
			}
			& input,
			label,
			.v-select__slot > div.v-select__selections {
				font-size: 0.9rem;
				color: $main-color !important;
			}
			& .v-select__slot > div.v-input__append-inner > div > i {
				color: $main-color !important;
			}
		}
	}

	&__container {
		padding: 0 16px 0 16px;
		@media (min-width: 300px) {
			padding: 0;
		}

		max-width: 265px;

		&--tablet {
			max-width: 506px;
		}

		&--desktop {
			max-width: 50%;
		}
	}
}

::v-deep .v-btn__content {
	font-size: 1.3rem;
	font-weight: 800;

	@media (min-width: 900px) {
		font-size: 1.6rem;
	}
}

::v-deep .v-text-field__suffix {
	font-size: 13px;
	color: blue !important;
}

::v-deep .v-label {
	width: 100% !important;
	max-width: unset !important;
}

.protest-strike-regular {
	font-family: 'Protest Strike', sans-serif;
	font-weight: 400;
	font-style: normal;
}

.poppins-thin {
	font-family: 'Poppins', sans-serif;
	font-weight: 100;
	font-style: normal;
}

.poppins-extralight {
	font-family: 'Poppins', sans-serif;
	font-weight: 200;
	font-style: normal;
}

.poppins-light {
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	font-style: normal;
}

.poppins-regular {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-style: normal;
}

.poppins-medium {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-style: normal;
}

.poppins-semibold {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-style: normal;
}

.poppins-bold {
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-style: normal;
}

.poppins-extrabold {
	font-family: 'Poppins', sans-serif;
	font-weight: 800;
	font-style: normal;
}

.poppins-black {
	font-family: 'Poppins', sans-serif;
	font-weight: 900;
	font-style: normal;
}

.poppins-thin-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 100;
	font-style: italic;
}

.poppins-extralight-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 200;
	font-style: italic;
}

.poppins-light-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	font-style: italic;
}

.poppins-regular-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-style: italic;
}

.poppins-medium-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-style: italic;
}

.poppins-semibold-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-style: italic;
}

.poppins-bold-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	font-style: italic;
}

.poppins-extrabold-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 800;
	font-style: italic;
}

.poppins-black-italic {
	font-family: 'Poppins', sans-serif;
	font-weight: 900;
	font-style: italic;
}
</style>

