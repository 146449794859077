<script>
export default {
	props: ['AppBar'],
	hookZone: ['appbar-bottom'],
}
</script>
<template>
	<div
		v-if="$b.td"
		class="d-flex justify-center primary"
		:style="AppBar.makeAppbarTransparent && AppBar.scrollIsAtTop && 'background: transparent!important'"
	>
		<Container class="py-0">
			<AppBar-Bottom />
		</Container>
	</div>
</template>
