import { make } from 'vuex-pathify'

export default (options) => {
	let { srv, ssr, store } = options
	let keys = [
		{ srvKey: 'cyberSecondsLeft', storeKey: 'cybermonday' },
		{ srvKey: 'hotSaleSecondsLeft', storeKey: 'hotsale' },
	]
	keys.forEach(({ srvKey, storeKey }) => {
		let secondsLeft = Math.max(0, Number(srv(srvKey) || 0))

		const state = { secondsLeft }

		store.registerModule(storeKey, {
			namespaced: true,
			state,
			mutations: make.mutations(state),
			actions: make.actions(state),
		})
		if (!ssr && secondsLeft) {
			let itv = setInterval(() => {
				store.set(storeKey + '/secondsLeft', --secondsLeft)
				if (!secondsLeft) clearInterval(itv)
			}, 1000)
		}
	})
}
