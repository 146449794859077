import { views } from '@/v-shop/autoloader'

export default () => [
	{
		path: '/trabaja-con-nosotros',
		name: 'work-with-us',
		component: views.FormWorkWithUs,
	},
	{
		path: '/pre-cybermonday',
		name: 'pre-cybermonday',
		meta: { appComponent: { render: (h) => h('router-view') } },
		component: views.CyberMonday,
	},
	{
		path: '/pre-hotsale',
		name: 'pre-hotsale',
		meta: { appComponent: 'HotSale' },
	},
]

