<script>
import Base from '#/v-shop/vue-src/v-shop/offer-drawer/comp_OfferDrawer.vue'
export default {
	extends: Base,
	computed: {
		drawerWidth() {
			return this.$b.m ? '85%' : 400
		},
	},
}
</script>

