var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('v-container',{class:_vm.$bem('carousel-multiple')},[(_vm.data.textEnabled)?_c('Title',{attrs:{"title":_vm.data.title,"btn":{ link: _vm.data.link, linkText: _vm.data.linkText },"titleStyle":_vm.data.titleStyle}}):_vm._e(),_c('v-row',{class:{
			'flex-column-reverse': _vm.data.inverted && _vm.hasBoardProduct && _vm.$b.mt,
			'flex-row-reverse': _vm.data.inverted && _vm.hasBoardProduct && _vm.$b.d,
		}},[(_vm.hasBoardProduct)?_c('v-col',{staticClass:"secondary",class:_vm.boardProductCol,attrs:{"cols":_vm.$b.d ? 4 : 12}},[_c('ProductCard',{staticClass:"ma-10",attrs:{"product":_vm.data.boardProduct,"list-id":("related-products-board-product-" + (_vm.data.boardProduct.collectionId)),"verticalCard":_vm.$b.mt ? false : true,"featured":"","timerProduct":_vm.limitedTimeOffer ? true : false,"position":_vm.data.inverted ? 'left' : 'right'}})],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.hasBoardProduct && _vm.$b.d ? 8 : 12}},[_c('v-row',{class:{ 'flex-row-reverse': _vm.right }},[_c('v-col',{attrs:{"cols":"12","sm":_vm.top ? 12 : 2}},[_c('v-tabs',{staticClass:"h100",class:{
							'slider-right': _vm.left && _vm.$b.d,
							'top-underline': _vm.top && _vm.$b.d,
							'slider-top mb-3': _vm.top,
							'px-2': !_vm.top && _vm.$b.m,
						},attrs:{"show-arrows":"","centered":_vm.top,"center-active":_vm.top,"vertical":_vm.$b.d && !_vm.top,"color":_vm.$vars.cta,"slider-color":_vm.$vars.cta},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.data.tabs),function(item,n){return _c('v-tab',{key:n,class:{ 'justify-start': _vm.left, 'justify-end': _vm.right },attrs:{"active-class":"cta--text"}},[(_vm.data.titleType == 'icon')?_c('v-icon',[_vm._v(_vm._s(item.titleIcon))]):(_vm.data.titleType == 'img')?_c('Media',{attrs:{"src":item.titleImg}}):_c('span',[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1),_c('v-col',{class:{ 'border-left': _vm.left, 'border-right': _vm.right },attrs:{"cols":"12","sm":_vm.top ? 12 : 10}},[_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.data.tabs),function(item,n){return _c('v-tab-item',{key:n,staticClass:"pa-1"},[_c('div',[_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeProducts),expression:"activeProducts"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.activeProducts=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',[_c('v-btn',[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)],1),_c('option',[_c('v-btn',[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeProducts),expression:"activeProducts"}],staticClass:"d-flex flex-wrap"},_vm._l((_vm.el),function(product,i){return _c('div',{key:i,staticClass:"d-flex flex-column col-4 px-2"},[_c('ProductCard',{attrs:{"product":product,"list-index":i,"list-id":"related-products"}})],1)}),0)])])}),1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }