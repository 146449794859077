<script>
import BaseActionBarMobile from '#/v-shop-base-layout/vue-src/v-shop/appbar/comp_ActionBarMobile'

export default {
	extends: BaseActionBarMobile,
	data() {
		return {
			dynamicItems: {
				offer: {
					type: 'offer',
					icon: 'mdi-label-percent-outline',
					text: 'Ofertas',
					action: this.offerAction,
				},
				home: {
					type: 'home',
					icon: 'mdi-home-outline',
					text: 'Inicio',
					action: this.homeAction,
				},
				filter: {
					type: 'filter',
					icon: 'mdi-filter-outline',
					text: 'Filtros',
					action: this.filterAction,
				},
				buy: {
					type: 'buy',
					icon: 'mdi-cart-arrow-down',
					text: 'Comprar',
					action: this.buyAction,
					style: 'font-weight: bold',
				},
				loginRegister: {
					type: 'loginRegister',
					icon: 'mdi-account-outline',
					text: 'Cuenta',
					action: this.loginRegisterAction(),
				},
			},
		}
	},
	computed: {
		firstBtn() {
			if (
				!this.config.firstBtnText ||
				!this.config.firstBtnIcon ||
				!this.config.firstBtnColor ||
				!this.config.firstBtnUrl
			)
				return
			return {
				type: 'link',
				iconColor: this.config.firstBtnColor,
				icon: this.config.firstBtnIcon,
				text: this.config.firstBtnText,
				action: this.linkAction({ link: this.config.firstBtnUrl }),
			}
		},
		columnStyle() {
			return { paddingLeft: '5px !important', paddingRight: '5px !important', fontWeight: 'bold' }
		},
		visibleItems() {
			const showOfferBtn = true
			const routeName = this.$store.get('route/name')

			const firstItemMap = {
				home: this.firstBtn || this.dynamicItems.offer,
				product: this.dynamicItems.buy,
				shop: this.dynamicItems.filter,
			}

			const firstItem = firstItemMap[routeName] || this.dynamicItems.home
			const lastItem =
				routeName === 'shop' || routeName === 'product'
					? this.dynamicItems.home
					: this.dynamicItems.loginRegister
			let res = [firstItem, ...this.items.slice(0, 3), lastItem]
			if (showOfferBtn) return res
			return res.filter((r) => r.type !== 'offer')
		},
		cartBadge() {
			return this.$store.get('cart/order')?.items.reduce((sum, item) => sum + item.packQty, 0) || '0'
		},
		buyColor() {
			return this.cssVars.cta
		},
		buyStyle() {
			return { color: this.buyColor, fontWeight: 'bold' }
		},
		filterColor() {
			return this.cssVars.cta
		},
		filterStyle() {
			return { color: this.filterColor, fontWeight: 'bold' }
		},
		textStyle() {
			if (this.$ssr) return
			return { fontSize: window.innerWidth >= 340 ? '10px' : '8px' }
		},
		iconSize() {
			return this.$b.smAndDown ? '20px' : '26px'
		},
	},
	methods: {
		cartAction() {
			return () => this.$store.set('shop/cartDrawer', true)
		},
		offerAction() {
			this.$router.replace('/ofertas')
		},
		homeAction() {
			this.$router.replace('/')
		},
	},
}
</script>

