import { loadScript, unloadScript } from '@/utils/load-script'

export default (options) => {
	if (options.ssr) return

	const { eventer, srv } = options
	const icommDomain = srv('icommDomain')
	if (!icommDomain) return

	const src = window.location.protocol + '//externalassets.icommarketing.com/icomMkt_tracking_jquery.min.js'
	let lead = false

	eventer.listen('cart:item-added', () => {
		if (lead) return
		window._imMktOptions = {
			_setType: 'Lead',
			_setDomain: icommDomain,
			_itemsQuantity: -1,
			_amount: -1,
			_extraInfo: '',
		}
		loadScript(src).then(() => {
			lead = true
		})
	})

	eventer.listen('checkout:step-submit', async ({ stepKey }) => {
		if (stepKey === 'confirm') {
			try {
				unloadScript(src)
				window._imMktOptions = {
					_setType: 'Adq',
					_setDomain: icommDomain,
					_itemsQuantity: -1,
					_amount: -1,
					_extraInfo: '',
				}
				await loadScript(src)
				await new Promise((resolve) => setTimeout(resolve, 1000))
			} catch (err) {
				// null
			}
		}
	})
}
