<script>
export default {
	hookZone: ['end'],
	data() {
		return {
			certificates: this.$srv('FooterBottom.certificates', []),
		}
	},
}
</script>

<template>
	<v-row class="mt-2" :class="{ 'justify-center': $b.m, 'justify-end': $b.td }" v-if="certificates.length">
		<div>
			<Footer-Certificates :items="certificates" />
		</div>
	</v-row>
</template>
