<script>
import CarouselBase from '#/v-shop/vue-src/v-shop/dynamic-pages-components/comp_dpc-CarouselMultiple.vue'
export default {
	extends: CarouselBase,
}
</script>

<style lang="scss" scoped>
::v-deep .product-card-design1-with-timer-vertical,
.product-card-design2-with-timer-vertical,
.product-card-design3-with-timer-vertical,
.product-card-design4-with-timer-vertical,
.product-card-design5-with-timer-vertical,
.product-card-design6-with-timer-vertical,
.product-card-design7-with-timer-vertical,
.product-card-design8-with-timer-vertical {
	&__positionRight,
	&__positionLeft {
		border-radius: 30px;
	}
}
</style>
