<script>
import bannerDesktop from '../assets/work-with-us/banner-desktop.png'
import bannerMobile from '../assets/work-with-us/banner-mobile.png'

export default {
	lang: 'shop',
	metaInfo() {
		return {
			title: 'Trabajá con nosotros',
			meta: [{ vmid: 'w', name: 'description', content: 'Trabaja Con Nosotros' }],
		}
	},
	data() {
		return {
			name: '',
			email: '',
			phone: '',
			related: '',
			file: null,
			message: '',

			state: 'pending',
			loading: false,
			validation: {},

			bannerDesktop,
			bannerMobile,
		}
	},
	methods: {
		async readFile(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.onload = () => {
					resolve(reader.result)
				}
				reader.onerror = (error) => {
					reject(error)
				}
				reader.readAsText(file)
			})
		},
		async submit() {
			return await this.$shopApi.post({
				url: '/user-forms/work-with-us',
				data: {
					data: {
						name: this.name,
						email: this.email,
						phone: this.phone,
						related: this.related,
						message: this.message,
					},
				},
				files: { file: this.file },
				loading: (v) => (this.loading = v),
				onSuccess: () => {
					this.state = 'success'
				},
				onError: () => {
					this.state = 'error'
				},
				onValidation: ({ validation }) => (this.validation = validation),
			})
		},
	},
	computed: {
		banner() {
			return this.$b.m ? this.bannerMobile : this.bannerDesktop
		},
		ratio() {
			return this.$b.m ? 780 / 700 : 1920 / 451
		},
	},
}
</script>

<template>
	<div>
		<Media :src="banner" :ratio="ratio" />
		<Container class="pa-5">
			<form v-if="state != 'success'" @submit.prevent="submit()">
				<div style="max-width: 900px" class="mx-auto">
					<h1 class="pb-3 is-heading font-4">Sumate a nuestro equipo de trabajo</h1>
					<!-- <h3 class="pb-3 is-heading font-2">description</h3> -->
					<v-row>
						<v-col cols="12" md="6">
							<TextField outlined v-model="name" label="Nombre" :error-messages="validation.name" />
						</v-col>
						<v-col cols="12" md="6">
							<TextField
								outlined
								v-model="email"
								label="Correo electrónico"
								:error-messages="validation.email"
							/>
						</v-col>
						<v-col cols="12" md="6">
							<TextField
								outlined
								v-model="phone"
								label="Teléfono"
								:error-messages="validation.phone"
							/>
						</v-col>
						<v-col cols="12" md="6">
							<TextField outlined v-model="related" label="Postulación relacionada (Opcional)" />
						</v-col>
						<v-col cols="12">
							<v-file-input
								outlined
								v-model="file"
								label="Adjuntar Archivo (Opcional)"
								prepend-icon=""
								:error-messages="validation.file"
								accept=".pdf,.doc,.docx,.pages"
							/>
						</v-col>
						<v-col cols="12">
							<TextArea outlined v-model="message" label="Mensaje (Opcional)" />
						</v-col>
					</v-row>

					<div class="py-3 d-flex justify-end">
						<Button :color="$vars.cta" :width="$b.m ? '100%' : '20%'" type="submit" :loading="loading">
							Enviar
						</Button>
					</div>
				</div>
			</form>
			<div v-else class="text-center">
				<div class="py-3 font-5">Muchas gracias por Postularte</div>
				<div class="py-3">Evaluaremos tu cv y nos pondremos en contacto</div>
				<div class="py-6">
					<SafeLink class="cta--text font-3" :to="$shop.getShopRoute()">Visitar la Tienda</SafeLink>
				</div>
			</div>
		</Container>
	</div>
</template>

<style></style>
