<script>
import CategoriesMegaMenuCategoriesListBase from '#/v-shop-base-layout/vue-src/v-shop/appbar/comp_CategoriesMegaMenu-CategoriesList.vue'
import lowercaseFilter from '../mixins/lowercase-filter-mixin'

export default {
	extends: CategoriesMegaMenuCategoriesListBase,
	mixins: [lowercaseFilter],
}
</script>

<template>
	<div class="categories-list primary pa-0 rounded" style="height: auto">
		<div
			v-for="(
				{
					category: { name, children, id, familyUrlNames },
					icon,
					useIcon,
					iconType,
					iconImage,
					iconHtml,
				},
				i
			) of items"
			:key="id"
		>
			<router-link :to="$b.d ? $shop.getShopRoute({ categories: familyUrlNames }) : ''" :key="i">
				<v-hover v-slot="{ hover }">
					<div
						class="categories-list__item pa-2 pl-4 d-flex justify-space-between"
						:class="{
							'rounded-bl rounded-br': i === items.length - 1,
							'rounded-tl rounded-tr': i === 0,
							'on-hover': hover || hoverSubmenu === id,
						}"
						@mouseover="$emit('mouseover', id)"
						:input-value="id === hoverSubmenu"
						:style="{
							'border-bottom': i < items.length - 1 && '1px solid var(--strongtext)',
							background: hover || hoverSubmenu === id ? 'var(--error)' : '',
							color: hover || hoverSubmenu === id ? 'var(--errortext)!important' : '',
						}"
					>
						<div class="categories-list__title font-weight-bold d-flex align-center">
							<div v-if="useIcon" style="display: inline-block">
								<v-icon
									v-if="iconType === 'default'"
									:color="hover || hoverSubmenu === id ? $vars.errortext : $vars.primarytext"
									class="mr-2"
									>{{ icon }}</v-icon
								>
								<Media
									v-if="iconType === 'image'"
									class="mr-2"
									:src="iconImage"
									width="24px"
									height="24px"
								/>
								<div v-if="iconType === 'html'" v-html="iconHtml" class="mr-2" />
							</div>
							<div>
								{{ name | lowerCase }}
							</div>
						</div>
						<v-icon
							v-show="children.length > 0"
							size="small"
							:color="hover || hoverSubmenu === id ? $vars.errortext : $vars.primarytext"
							class="pr-2"
						>
							mdi-chevron-right
						</v-icon>
					</div>
				</v-hover>
			</router-link>
		</div>
	</div>
</template>

