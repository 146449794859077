<script>
import lowercaseFilter from '../mixins/lowercase-filter-mixin'

export default {
	props: ['CategoriesMegaMenu'],
	hookZone: ['submenu'],
	mixins: [lowercaseFilter],
}
</script>

<template>
	<div>
		<div
			v-for="item of CategoriesMegaMenu.itemsWithSubcategories"
			:key="item.id"
			v-show="CategoriesMegaMenu.hoverSubmenu === item.category.id"
		>
			<div
				class="appbar__mega-menu__submenu elevation-12 px-6 py-4 rounded rounded-l-0"
				:class="CategoriesMegaMenu.submenuClass"
				:style="CategoriesMegaMenu.cSubmenuStyles"
			>
				<v-row class="px-4 pt-4">
					<!-- Links -->
					<v-col
						:cols="CategoriesMegaMenu.hasImages(item) && 10"
						class="w100 h100"
						style="font-size: 12px"
					>
						<div class="h100" style="min-height: 0; column-count: 3; column-gap: 10px">
							<div
								style="break-inside: avoid-column"
								class="pb-4"
								v-for="child of item.category.children"
								:key="child.id"
							>
								<router-link
									:to="
										$shop.getShopRoute({
											categories: [item.category.urlName, child.urlName],
										})
									"
								>
									<div class="py-2" style="font-size: 14px; font-weight: bold; color: var(--link)">
										{{ child.name | lowerCase }}
									</div>
								</router-link>

								<!-- Sublink -->
								<router-link
									v-for="(subChild, i) in child.children"
									:key="i"
									:to="
										$shop.getShopRoute({
											categories: [item.category.urlName, child.urlName, subChild.urlName],
										})
									"
								>
									<div class="py-2">{{ subChild.name | lowerCase }}</div>
								</router-link>
							</div>
						</div>
					</v-col>
					<!-- Images -->
					<v-col class="pl-0" v-if="CategoriesMegaMenu.hasImages(item)">
						<v-row>
							<v-col
								v-for="(data, n) in CategoriesMegaMenu.imageData(item)"
								:key="n"
								col="6"
								:class="
									CategoriesMegaMenu.dense && 'appbar__mega-menu__submenu__images-container--dense'
								"
							>
								<CategoriesMegaMenu-Image v-bind="data" />
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';

.appbar__mega-menu {
	&__submenu {
		position: absolute;
		z-index: 5;
		top: 0;
		left: 95%;
		background: var(--secondary) !important;
		height: 100%;
		overflow: overlay;

		&__images-container--dense {
			display: flex;
			justify-content: center;
			gap: 35px;
		}

		&::-webkit-scrollbar {
			width: 3px;
		}

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(145, 144, 144, 0.3);
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--base);
			outline: 1px solid rgb(143, 143, 144);
		}

		a {
			color: var(--linktext) !important;
		}
	}
}
</style>
