<script>
export default {
	hookZone: ['bottom'],
	showHook(_, parent) {
		return !!parent.$store.get('cybermonday/secondsLeft')
	},
	data() {
		return { close: false, show: false, interval: null, ms: 500 }
	},
	mounted() {
		if (this.$b.m && this.$store.get('cybermonday/secondsLeft')) {
			this.interval = setTimeout(() => {
				this.show = true
			}, this.ms)
		}
	},
	beforeDestroy() {
		if (this.interval) clearInterval(this.interval)
	},
}
</script>

<template>
	<div
		:class="[
			'timer-container pr-5 py-2',
			close && 'timer-container--close',
			1 && 'timer-container--desktop',
		]"
	>
		<CybermondayTimer @close="close = !close" />
	</div>
	<!-- <div v-else class="timer-container">
		<v-dialog v-model="show">
			<div>
				<CybermondayTimer @close="show = false" style="border-radius: 10px" />
			</div>
		</v-dialog>
	</div> -->
</template>
<style scoped lang="scss">
.timer-container {
	position: relative;
	z-index: 5;
	transition: all 0.25s ease;

	&--desktop {
		position: fixed;
		top: 20%;
		left: 0;
		transform: unset;
		border-radius: 0 10px 10px 0;
	}

	&--close {
		left: -305px;
	}
}
</style>
