var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"categories-list primary pa-0 rounded",staticStyle:{"height":"auto"}},_vm._l((_vm.items),function(ref,i){
			var ref_category = ref.category;
			var name = ref_category.name;
			var children = ref_category.children;
			var id = ref_category.id;
			var familyUrlNames = ref_category.familyUrlNames;
			var icon = ref.icon;
			var useIcon = ref.useIcon;
			var iconType = ref.iconType;
			var iconImage = ref.iconImage;
			var iconHtml = ref.iconHtml;
return _c('div',{key:id},[_c('router-link',{key:i,attrs:{"to":_vm.$b.d ? _vm.$shop.getShopRoute({ categories: familyUrlNames }) : ''}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var hover = ref.hover;
return [_c('div',{staticClass:"categories-list__item pa-2 pl-4 d-flex justify-space-between",class:{
						'rounded-bl rounded-br': i === _vm.items.length - 1,
						'rounded-tl rounded-tr': i === 0,
						'on-hover': hover || _vm.hoverSubmenu === id,
					},style:({
						'border-bottom': i < _vm.items.length - 1 && '1px solid var(--strongtext)',
						background: hover || _vm.hoverSubmenu === id ? 'var(--error)' : '',
						color: hover || _vm.hoverSubmenu === id ? 'var(--errortext)!important' : '',
					}),attrs:{"input-value":id === _vm.hoverSubmenu},on:{"mouseover":function($event){return _vm.$emit('mouseover', id)}}},[_c('div',{staticClass:"categories-list__title font-weight-bold d-flex align-center"},[(useIcon)?_c('div',{staticStyle:{"display":"inline-block"}},[(iconType === 'default')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":hover || _vm.hoverSubmenu === id ? _vm.$vars.errortext : _vm.$vars.primarytext}},[_vm._v(_vm._s(icon))]):_vm._e(),(iconType === 'image')?_c('Media',{staticClass:"mr-2",attrs:{"src":iconImage,"width":"24px","height":"24px"}}):_vm._e(),(iconType === 'html')?_c('div',{staticClass:"mr-2",domProps:{"innerHTML":_vm._s(iconHtml)}}):_vm._e()],1):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm._f("lowerCase")(name))+" ")])]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(children.length > 0),expression:"children.length > 0"}],staticClass:"pr-2",attrs:{"size":"small","color":hover || _vm.hoverSubmenu === id ? _vm.$vars.errortext : _vm.$vars.primarytext}},[_vm._v(" mdi-chevron-right ")])],1)]}}],null,true)})],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }